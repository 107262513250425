function getUrlParams(url: any) {
    if (typeof url == 'undefined') {
        url = window.location.search;
    }

    url = url.split('#')[0];

    const urlParams: any = {};
    let queryString = url.split('?')[1];
    if (!queryString) {
        if (url.search('=') !== false) {
            queryString = url;
        }
    }

    if (queryString) {
        const keyValuePairs = queryString.split('&');
        for (let i = 0; i < keyValuePairs.length; i++) {
            const keyValuePair = keyValuePairs[i].split('=');
            const paramName = keyValuePair[0];
            const paramValue = keyValuePair[1] || '';

            urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
        }
    }

    return urlParams;
}

const exp = {
    getUrlParams: getUrlParams,
};

export default exp;
