const stages = {
    currentProjectIndex: 0,
    projects: [
        {
            description:
                'На последнем курсе инженерной магистратуры несколько друзей решили запустить стартап по производству экономичных и компактных городских электроскутеров. Помогите молодой команде приоритизировать задачи на каждом этапе проекта.',
            stages: [
                {
                    id: 0,
                    title: 'Оформление идеи проекта (Пре-продакшн)',
                    isCurrentStage: true,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Запуск рекламной кампании',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 1,
                            title: 'Разработка технологической концепции',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 2,
                            title: 'Создание ИП',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 3,
                            title: 'Подбор команды',
                            stars: 0,
                            answers: ['организатор'],
                        },
                        {
                            id: 4,
                            title: 'Проведение переговоров с заказчиками',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 5,
                            title: 'Создание бизнес-плана',
                            stars: 0,
                            answers: ['организатор'],
                        },
                        {
                            id: 6,
                            title: 'Поиск инвесторов',
                            stars: 0,
                            answers: ['предприниматель', 'организатор'],
                        },
                    ],
                },
                {
                    id: 1,
                    title: 'Первые шаги проекта (пре-продакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Участие в отраслевых конференциях',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 1,
                            title: 'Создание технического задания для производства',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 2,
                            title: 'Поиск клиента для MVP',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 3,
                            title: 'Покупка домена для сайта',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 4,
                            title: 'Анализ рынка',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 5,
                            title: 'Построение иерархии менеджмента',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 6,
                            title: 'Поиск ресурсов',
                            stars: 0,
                            answers: ['организатор'],
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'Работа над MVP (Продакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Запуск краудфандинговой компании',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 1,
                            title: 'Построение схемы взаимодействия с поставщиками и партнёрами',
                            stars: 0,
                            answers: ['организатор'],
                        },
                        {
                            id: 2,
                            title: 'Долгосрочная аренда обширных производственных помещений',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 3,
                            title: 'Разработка вирусного ролика',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 4,
                            title: 'Поиск инвесторов',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 5,
                            title: 'Финализация технической карты и создание прототипа',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 6,
                            title: 'Проведение стратегической сессии по позиционированию бренда',
                            stars: 0,
                            answers: [],
                        },
                    ],
                },
                {
                    id: 3,
                    title: 'Начало производства продукта (Продакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Продолжение переговоров с инвесторами',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 1,
                            title: 'Доработка с изначальной идеи',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 2,
                            title: 'Старт производства',
                            stars: 0,
                            answers: ['организатор', 'технолог'],
                        },
                        {
                            id: 3,
                            title: 'Определение состава учредителей и руководства',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 4,
                            title: 'Закупка производственного оборудования',
                            stars: 0,
                            answers: ['организатор', 'технолог'],
                        },
                        {
                            id: 5,
                            title: 'Аренда производственных помещений',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 6,
                            title: 'Запуск маркетинговой компании «разогрева» рынка',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                    ],
                },
                {
                    id: 4,
                    title: 'Начало продвижения (Постпродакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Привлечение дополнительных технических специалистов',
                            stars: 0,
                            answers: ['организатор', 'технолог'],
                        },
                        {
                            id: 1,
                            title: 'Активный выход на зарубежные рынки',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 2,
                            title: 'Оформление кредита на расшироение бизнеса',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 3,
                            title: 'Позиционирование продукта и оформление бренда',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 4,
                            title: 'Масштабирование продаж',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 5,
                            title: 'Технологическое усовершенствование производства',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 6,
                            title: 'Создание логотипа',
                            stars: 0,
                            answers: [],
                        },
                    ],
                },
            ],
        },
        {
            description:
                'Поработав в event бизнесе, трое коллег решили запустить в своем регионе (Санкт-Петербург) франшизу «Communication badges» — незаменимых на крупных конференциях устройств автоматической записи переговоров со сбором цифрового следа и  инфо-полями в дополненной реальности. Помогите им приоритезировать задачи в развитии проекта.',
            stages: [
                {
                    id: 0,
                    title: 'Оформление идеи проекта (Пре-продакшн)',
                    isCurrentStage: true,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Анализ спроса',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 1,
                            title: 'Развитие В2В',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 2,
                            title: 'Детальная проработка пользовательских сценариев',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 3,
                            title: 'Создание бизнес плана',
                            stars: 0,
                            answers: ['организатор'],
                        },
                        {
                            id: 4,
                            title: 'Регистрация ООО',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 5,
                            title: 'Запуск рекламной компании',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 6,
                            title: 'Поиск инвесторов',
                            stars: 0,
                            answers: [],
                        },
                    ],
                },
                {
                    id: 1,
                    title: 'Первые шаги проекта (пре-продакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Закупка оборудования',
                            stars: 0,
                            answers: ['организатор'],
                        },
                        {
                            id: 1,
                            title: 'Проведение исследований по применению AR на конференциях',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 2,
                            title: 'Аренда офиса',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 3,
                            title: 'Выбор эффективных маркетинговых каналов',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 4,
                            title: 'Анализ конкурентов',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 5,
                            title:
                                'Решение логистических задач с зарядкой устройств и передачи данных',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 6,
                            title: 'Наращивание объема продаж',
                            stars: 0,
                            answers: [],
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'Работа над MVP (Продакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Приобритение собственных мощностей',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 1,
                            title: 'Приглашение потенциальных заказчиков на пилотный запуск',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 2,
                            title: 'Детальный сбор и анализ проблем и ошибок пилотного запуска',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 3,
                            title: 'Организация PR покрытия пилотного запуска',
                            stars: 0,
                            answers: ['организатор'],
                        },
                        {
                            id: 4,
                            title: 'Оптимизация протоколов взаимодействия с поставщиками',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 5,
                            title: 'Переговоры с партнёрами по пилотному запуску',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                    ],
                },
                {
                    id: 3,
                    title: 'Начало производства продукта (Продакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Разработка системы бонусных выплат ТОП-менеджерам',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 1,
                            title: 'Развитие франшизы',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 2,
                            title: 'Инвестирование средств в дочерние проекты',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 3,
                            title: 'Выход на гос. корпорации',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 4,
                            title:
                                'Стыковка со сторонними сервисами быстрой обработки данных (текстов)',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 5,
                            title: 'Активные В2В продажи',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                        {
                            id: 6,
                            title: 'Создание сообщества пользователей',
                            stars: 0,
                            answers: ['организатор', 'предприниматель'],
                        },
                    ],
                },
                {
                    id: 4,
                    title: 'Начало продвижения (Постпродакшн)',
                    isCurrentStage: false,
                    maxStars: 6,
                    availableStars: [true, true, true],
                    usedStars: 0,
                    ratings: [
                        {
                            id: 0,
                            title: 'Открытие нескольких параллельных офисов',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 1,
                            title: 'Ребрендинг для привлечения новых клиентов',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 2,
                            title: 'Отладка и оптимизация финансовых потоков',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 3,
                            title: 'Выход на гос. корпорации',
                            stars: 0,
                            answers: ['организатор'],
                        },
                        {
                            id: 4,
                            title: 'Развитие собственного сервиса семантического анализа данных',
                            stars: 0,
                            answers: ['технолог'],
                        },
                        {
                            id: 5,
                            title: 'Создание воронки продаж',
                            stars: 0,
                            answers: [],
                        },
                        {
                            id: 6,
                            title: 'Таргетинговая рекламная компания онлайн',
                            stars: 0,
                            answers: ['предприниматель'],
                        },
                    ],
                },
            ],
        },
    ],
};

export default stages;
