import storeService from './storeService';
import actions from '../store/actions/types';
import IntegrationParams from '../types/IntegrationParams';
import urlParams from './urlParams';
import WarningType from '../types/WarningType';
import Mode from '../types/Mode';

class DispatcherService {
    setParamsByIframe = (): void => {
        let iframeParams = urlParams.getUrlParams(window.location.href);

        const token = iframeParams.token,
            unti_id = iframeParams.unti_id,
            activity_uuid = iframeParams.activity_uuid;

        storeService.getStore().dispatch({
            type: actions.SET_INTEGRATION_PARAMS,
            payload: {
                token: token,
                unti_id: unti_id,
                activity_uuid: activity_uuid,
            } as IntegrationParams,
        });
    };

    setParamsByElement = (element: HTMLElement | null): void => {
        if (element) {
            const token = element.getAttribute('data-user-token'),
                unti_id = element.getAttribute('data-unti-id'),
                activity_uuid = element.getAttribute('data-activity-uuid');

            storeService.getStore().dispatch({
                type: actions.SET_INTEGRATION_PARAMS,
                payload: {
                    token: token,
                    unti_id: unti_id,
                    activity_uuid: activity_uuid,
                } as IntegrationParams,
            });
        }
    };

    resetStageStars = () => {
        storeService.getStore().dispatch({
            type: actions.RESET_CURRENT_STAGE_STARS,
            payload: null,
        });
    };

    nextStage = (id: number) => {
        storeService.getStore().dispatch({
            type: actions.SET_CURRENT_STAGE,
            payload: {
                currentStageId: id,
            },
        });
    };

    nextProject = () => {
        let projectIndex = storeService.getStore().getState().general.currentProjectIndex + 1;

        storeService.getStore().dispatch({
            type: actions.SET_CURRENT_PROJECT_INDEX,
            payload: {
                index: projectIndex,
            },
        });
    };

    setRatingStars = (id: number, stars: number) => {
        storeService.getStore().dispatch({
            type: actions.SET_RATING_STARS,
            payload: {
                id: id,
                stars: stars,
            },
        });
    };

    setMode = (mode: Mode) => {
        storeService.getStore().dispatch({
            type: actions.SET_MODE,
            payload: mode,
        });
    };

    setWarningType = (type: WarningType): void => {
        storeService.getStore().dispatch({
            type: actions.SET_WARNING_TYPE,
            payload: type,
        });
    };
}

const dispatcherService = new DispatcherService();
export default dispatcherService;
