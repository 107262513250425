import actions from '../actions/types';
import GeneralState from '../../types/GeneralState';
import StoreAction from '../../types/StoreAction';
import gameData from '../gameData';
import { updateObject } from './utility';

const initialState: GeneralState = gameData;

export default (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.SET_RATING_STARS: {
            let currentState = Object.assign({}, state),
                projects = currentState.projects.slice(),
                stages = projects[currentState.currentProjectIndex].stages;

            let currentStage = stages.find(stage => stage.isCurrentStage);

            if (currentStage) {
                currentStage.availableStars[action.payload.stars - 1] = false;
                currentStage.usedStars = 0;
                currentStage.availableStars.forEach((canChoiceStar, index) => {
                    let stars = index + 1;

                    if (!canChoiceStar && currentStage) currentStage.usedStars += stars;
                });

                let rating = currentStage.ratings.find(rating => rating.id === action.payload.id);
                if (rating) {
                    rating.stars = action.payload.stars;
                }
            }

            return updateObject(state, { projects });
        }
        case actions.SET_CURRENT_STAGE: {
            let currentState = Object.assign({}, state),
                projects = currentState.projects.slice(),
                stages = projects[currentState.currentProjectIndex].stages;

            let nextStageId = action.payload.currentStageId + 1;
            let currentStage = stages.find(stage => stage.isCurrentStage);
            let nextCurrentStage = stages.find(stage => stage.id === nextStageId);

            if (nextCurrentStage && currentStage) {
                currentStage.isCurrentStage = false;
                nextCurrentStage.isCurrentStage = true;
            }

            return updateObject(state, { projects });
        }
        case actions.RESET_CURRENT_STAGE_STARS: {
            let currentState = Object.assign({}, state),
                projects = currentState.projects.slice(),
                stages = projects[currentState.currentProjectIndex].stages;

            let currentStage = stages.find(stage => stage.isCurrentStage);

            if (currentStage) {
                currentStage.availableStars = [true, true, true];
                currentStage.usedStars = 0;
                currentStage.ratings.forEach(rating => {
                    rating.stars = 0;
                });
            }

            return updateObject(state, { projects });
        }
        case actions.SET_CURRENT_PROJECT_INDEX: {
            return updateObject(state, { currentProjectIndex: action.payload.index });
        }
        case actions.SET_INTEGRATION_PARAMS: {
            let currentState = Object.assign({}, state);
            currentState.integrationParams = action.payload;

            return updateObject(state, currentState);
        }
        case actions.SET_MODE: {
            let currentState = Object.assign({}, state);
            currentState.mode = action.payload;

            return updateObject(state, currentState);
        }
        case actions.SET_WARNING_TYPE: {
            let currentState = Object.assign({}, state);
            currentState.warningType = action.payload;

            return updateObject(state, currentState);
        }
        default:
            return state;
    }
};
