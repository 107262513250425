import GeneralState from '../types/GeneralState';

const data = require('../data/' + (process.env.REACT_APP_DATA! || 'general') + '/stages').default;

const state: GeneralState = {
    mode: 'select',
    warningType: 'none',
    integrationParams: {
        token: '',
        unti_id: '',
        activity_uuid: '',
    },
    ...data,
};

export default state;
