const actions = {
    SET_RATING_STARS: 'SET_RATING_STARS',
    SET_CURRENT_STAGE: 'SET_CURRENT_STAGE',
    RESET_CURRENT_STAGE_STARS: 'RESET_CURRENT_STAGE_STARS',
    SET_CURRENT_PROJECT_INDEX: 'SET_CURRENT_PROJECT_INDEX',
    SET_INTEGRATION_PARAMS: 'SET_INTEGRATION_PARAMS',
    SET_MODE: 'SET_MODE',
    SET_WARNING_TYPE: 'SET_WARNING_TYPE',
};

export default actions;
