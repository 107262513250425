import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import reducers from './store/reducers';

import storeService from './services/storeService';
import { composeWithDevTools } from 'redux-devtools-extension';

import pjson from '../package.json';
import dispatcherService from './services/dispatcherService';

const App = React.lazy(() => import('./App'));

const store: Store = createStore(reducers, {}, composeWithDevTools());
storeService.setStore(store);

const root = document.getElementById('webbot');

let isFramed;
try {
    // eslint-disable-next-line no-restricted-globals
    isFramed = window !== window.top || document !== top.document || self.location !== top.location;
} catch (e) {
    isFramed = true;
}

if (isFramed) {
    dispatcherService.setParamsByIframe();
} else {
    dispatcherService.setParamsByElement(root);
}

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<></>}>
            <App />
        </Suspense>
    </Provider>,
    root
);

console.log(`%c Project Game version: ${pjson.version}`, 'background-color: #ffdd2d; color: #333;');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
